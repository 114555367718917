.services__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services__card{
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.services__card:hover {
    transform: translateY(-10px);
}

.services__card:nth-child(1) {
    background-color: rgb(108, 108, 229);
    box-shadow: 0px 5px 20px 0px rgb(108, 108, 229 / 50%);
}

.services__card:nth-child(2) {
    background-color: rgb(249, 215, 76);
    box-shadow: 0px 5px 20px 0px rgb(249, 215, 76 / 50%);
}

.services__card:nth-child(3) {
    background-color: rgb(249, 123, 139);
    box-shadow: 0px 5px 20px 0px rgb(249, 123, 139 / 50%);
}

.services__img {
    margin-bottom: 1.25rem;
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}

.services__description {
    color: #f8f9fa;
}

.services__card:nth-child(2) .services__title {
    color: var(--title-color);
}

.services__card:nth-child(2) .services__description {
    color: var(--text-color);
}